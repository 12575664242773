<template>
    <v-text-field
        v-model="localValue"
        type="time"
        :label="label"
        :rules="rules"
        :readonly="readonly"
        :required="required"
        :dense="dense"
        :outlined="outlined"
        :hide-details="hideDetails"
        :disabled="disabled" />
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null
        },
        rules: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            localValue: null
        }
    },
    watch: {
        localValue: {
            handler(localValue) {
                if (this.value == localValue) {
                    return;
                }
                this.$emit("input", localValue);
            }
        },
        value: {
            immediate: true,
            handler(value) {
                this.localValue = value;
            }
        }
    }
};
</script>
